import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {css, cx} from "emotion";
import {TextField, InputAdornment, Fab, Button, useMediaQuery, Theme, Grow, Paper, Popper} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from '@material-ui/icons/PersonOutlined';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {useTranslate} from "ra-core";

import {ClientCreate} from "./ClientCreate/ClientCreate";
import {getClientName, getClientAddress} from "../repository";
import {getClients, getClient} from "../../queries";
import {fab} from "../components/CalendarStyles";
import {PersonType} from "../enums/PersonType";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from "@material-ui/icons/CreateOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {getAppointmentCard} from '../../views/ClientsList'
import {repeatOptions} from "../../constants";
import SearchClientImage from '../../assets/search_client.png'

const containerStyle = css`
  width: 100%;
  position: relative;
  z-index: 0;
`;
const searchContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 15px;
`;
const modal = css`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
`;
const closeIconStyle = css`
  cursor: pointer;
`;
const clientContainer = css`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  padding-left: 0px;
  width: 100%;
  cursor: pointer;
  border-bottom: solid 1px lightgray;
`;
const chosenClientStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 6px 8px;
  width: 100%;
`;

const removeClientStyle = css`
  position: absolute !important;
  right: 10px !important;
  width: 20px !important;
  cursor: pointer;
`;

const paperStyle = css`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  padding: 10px;
  font-size: 18px;
  color: black !important;
  background-color: rgba(255, 255, 255, 1) !important;
`
const popperMenuStyle = css`
  height: 48px;
  justify-content: left;
  text-align: center;
  display: flex
`

const appointmentContainer = css`
  padding-left: 10px;
  padding-top: 15px;
  padding-right: 10px;
  padding-bottom: 15px;
`
const titleBorder = css`
  border-bottom: #E4E4E4 solid;
  padding-bottom: 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
`

const imgStyle = css`
  width: 100%;
  height: auto;
  max-width: 140px;
  margin: 40px auto auto;
`

const searchTextStyle = css`
    height: 40px;
    width: 214px;
    color: rgba(0,0,0,0.38);
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
   margin: auto;
`

interface IClientSearchProps {
  setValue: Dispatch<SetStateAction<string | null>>;
  client: any;
  afterSelect?: any;
  warnOfFreeAppointment: boolean;
}

const PAGE_SIZE = 9;

export const ClientSearch = ({
  setValue,
  client: selectedClient,
  warnOfFreeAppointment,
  afterSelect,
}: IClientSearchProps) => {
  const translate = useTranslate();
  const [isCreateClientModalOpen, setIsCreateClientModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [clientsLoading,] = useState(false);
  const [clientsError,] = useState(false);
  const [redraw,setRedraw] = useState(false);
  const [clients,] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [popperOpened, setPopperOpened] = useState<any>(false);
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  useEffect(() => {
    (
      async () => {
        if (selectedClient && !selectedClient.appointments) {
          const fullData = await getClient(
            {
              id: selectedClient.id
            },
            true)
          if(fullData && fullData.data && fullData.data.appointments) {
            selectedClient.appointments = fullData.data.appointments
          }
          setRedraw(!redraw)
        }
      }
    )()
  }, []);

  useEffect(() => {
    (
      async () => {

        if (!clientsLoading) {
          if (!search) {
            setFilteredClients(clients.slice(0,PAGE_SIZE));
          } else {
            const results = (await getClients({
              filter: {
                expression: search
              },
              pagination: {
                page: 0,
                perPage: PAGE_SIZE,
              },
              sort: {
                field: 'firstName',
                order: 'ASC',
              }
            }, true))
            setFilteredClients(results?.data || []);
          }
        }
      }
    )()
  }, [clients, clientsLoading, search]);

  if (clientsError) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getClientLetters(client) {
    let name = '';
    if(client.firstName) {
     name += client.firstName[0].toUpperCase()
    }
    if(client.lastName) {
      name += client.lastName[0].toUpperCase()
    }
    if (!name && client.companyName) {
      return client.companyName[0].toUpperCase()
    }
    if (!name) {
      return 'A'
    }
    return name
  }

  const future: any = [];
  const previous: any = [];
  if (selectedClient && selectedClient.appointments && selectedClient.appointments.length > 0) {
    let data = selectedClient.appointments
      .filter(a => a.status !== 'CANCELED')
      .map(a => {
        const start = new Date(a.start);
        const end = new Date(a.end);
        const hasRepeat = a.repeat && a.repeat.toLowerCase() !== 'non si ripete'
        const repeatFrequency = hasRepeat ? repeatOptions[a.repeat] : 0;
        const title = a.client ? getClientName(a.client) : '';
        return {...a, start, end, title, hasRepeat, repeatFrequency};
      });
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].start > Date.now()) {
        future.push(data[i])
      } else {
        previous.push(data[i])
      }
    }
  }

  return (
    <div className={containerStyle}>
      <div className={searchContainer}>
        {!selectedClient && (<TextField
          value={search}
          placeholder={translate("sps.labels.search_entity.clients")}
          style={{flex: 1, marginBottom: 15}}
          onChange={e => {
            return setSearch((e.currentTarget.value || ""))
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <CloseIcon
                    className={closeIconStyle}
                    onClick={() => setSearch("")}
                  />
                )}
              </InputAdornment>
            )
          }}
          error={warnOfFreeAppointment && !selectedClient}
          helperText={
            warnOfFreeAppointment && !selectedClient
              ? translate("sps.labels.requiredField")
              : ""
          }
        />)}
        {selectedClient && (
          <div className={chosenClientStyle}>
            <ListItemAvatar>
              <Avatar style={{
                backgroundColor: 'rgba(207,150,207,0.3)',
                color: '#702588'
              }}>{getClientName(selectedClient)[0] || '-'}</Avatar>
            </ListItemAvatar>
            <div>{`${getClientName(selectedClient)}`}</div>
            <MoreHorizIcon
              className={removeClientStyle}
              onClick={(e) => {
                if (popperOpened) {
                  setPopperOpened(false)
                } else {
                  setPopperOpened(e.currentTarget)
                }
              }}
            />
          </div>
        )}

        {selectedClient && (
          <div className={appointmentContainer}>
            <h2 className={titleBorder}>Futuri ({future.length})</h2>
            {future.map(ap => {
              return <>{getAppointmentCard(ap, translate, true)}</>
            })}
            <h2 className={titleBorder}>Passati ({previous.length})</h2>
            {previous.slice(0, 5).map(ap => {
              return <>{getAppointmentCard(ap, translate, true)}</>
            })}
          </div>
        )}

        {popperOpened && (
          <Popper
            transition
            disablePortal
            role={undefined}
            anchorEl={popperOpened}
            open={Boolean(popperOpened)}
            placement={'bottom-start'}
            className={css`z-index: 1000;`}
          >
            {({TransitionProps, placement}) => (
              <Grow {...TransitionProps}>
                <Paper className={paperStyle}>
                  <div onClick={() => {
                    setValue(null)
                    setPopperOpened(false)
                  }}
                     className={popperMenuStyle}
                     style={{marginTop: '5px'}}
                  >
                    <DeleteIcon style={{marginRight: '5px'}}/> {translate('sps.labels.remove_from_appointment')}
                  </div>
                  <div onClick={() => {
                    setPopperOpened(false)
                    window.open('/client/' + selectedClient.id, "_blank")
                  }}
                       className={popperMenuStyle}
                  >
                    <EditIcon style={{marginRight: '5px'}}/> {translate('sps.labels.edit')}
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        {!selectedClient && !isSmall && (
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon/>}
            style={{flex: 1, marginBottom: 15}}
            onClick={() => {
              setIsCreateClientModalOpen(true);
            }}
          >
            {translate("sps.labels.add_new_client")}
          </Button>
        )}
        {!selectedClient && !search && (
          <>
            <img alt={'Search Client'} src={SearchClientImage} className={imgStyle}/>
            <span className={searchTextStyle}>Utilizza la ricerca per aggiungere o creare un nuovo cliente. </span>
          </>
        )}
        {!clientsLoading && (
          <>
            {!selectedClient && search && filteredClients.map((client: any) => (
              <div
                key={client.id}
                className={clientContainer}
                onClick={() => {
                  setValue(client);
                  setSearch("");
                  afterSelect && afterSelect(client)
                }}
              >
                {client.personType === PersonType.PRIVATE ? <PersonIcon/> : <LocationCityIcon/>}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span
                    className={cx([
                      client &&
                      selectedClient &&
                      selectedClient.id === client.id &&
                      css`
                            background-color: #ccc;
                          `,
                      css`
                          margin: auto;
                        `
                      ])}
                    style={{marginLeft: '15px'}}
                    >{getClientName(client)}</span>
                  <span style={{
                    marginLeft: '15px',
                    marginTop: '5px',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1.43,
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}>{getClientAddress(client)}</span>
                </div>
                </div>
              ))}
          </>
        )}
        <Modal open={isCreateClientModalOpen}>
          <div className={modal}>
            <ClientCreate
              handleClose={(client) => {
                setValue(client);
                setSearch("");
                setIsCreateClientModalOpen(false)
                afterSelect && afterSelect(client)
              }}
            />
          </div>
        </Modal>
        {isSmall && !selectedClient && (<Fab color="primary" className={fab} onClick={() => {
          setIsCreateClientModalOpen(true);
        }}>
          <AddIcon/>
        </Fab>)
        }
      </div>
    </div>
  );
};
